/** @jsx jsx */
import { useMemo, useEffect } from "react"
import { jsx, Box, Grid, Container, Themed, Heading } from "theme-ui"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import HeaderSlidesLive from "../components/post/HeaderSlidesLive"
import theme from "../gatsby-plugin-theme-ui"
import articleTypography from "../styles/article-typography"
import parse from "html-react-parser"
import { replaceCode } from "../components/PostCode"
import Author from "../components/post/Author"
import Meta from "../components/post/Meta"
import SEO from "../components/seo"
import ContributeCta from "../components/ContributeCta"

const BlogPostPresentationTemplate = ({ data: { previous, next, post } }) => {
  const fields = useMemo(() => {
    return {
      title: post.title,
      excerpt: post.excerpt,
      featuredImage: {
        data: getImage(post.featuredImage?.node?.localFile),
        alt: post.featuredImage?.node?.altText || post.title || ``,
      },
      date: {
        formatted: post.date,
        machineReadable: post.dateMachineReadable,
      },
      authors: post.postCustomFields?.authors?.map(author => {
        return {
          image: {
            data: author?.featuredImage?.node?.localFile?.childImageSharp
              ?.gatsbyImageData,
            alt:
              author?.featuredImage?.altText ||
              author?.authorFields?.name ||
              ``,
          },
          name: author?.authorFields?.name,
          shortBio: author?.authorFields?.shortbio || ``,
          bio: author?.authorFields?.bio || ``,
          twitter: author?.authorFields?.twitter || null,
          linkedin: author?.authorFields?.linkedin || null,
          website: author?.authorFields?.website || null,
          id: author?.id,
        }
      }),
      topic: {
        title: post.postCustomFields?.topic?.title,
        accentColor:
          post.postCustomFields?.topic?.topicCustomFields.accentColor ||
          theme.colors.primary.base,
        slug: post.postCustomFields?.topic?.slug,
      },
      tags: post.tags?.nodes,
      slidesliveId: post.postCustomFields?.slideslive,
    }
  }, [post])

  return (
    <Layout>
      <SEO yoastSeo={post.seo} />
      <article>
        <HeaderSlidesLive fields={fields} />

        {!!post.content && (
          <Box as="section" mt={10} mb={9}>
            <Grid
              sx={{
                gridGap: 0,
                gridTemplateColumns:
                  "[full-start] minmax(25px,auto) [wide-start] minmax(auto,207.5px) [main-start] calc(min(730px, 100% - 50px)) [main-end] minmax(auto,207.5px) [wide-end] minmax(25px,auto) [full-end]",
                "& > *": {
                  gridColumn: "main-start/main-end",
                },
                ...articleTypography,
              }}
            >
              <Meta date={fields.date} tags={fields.tags} />
              {parse(post.content, {
                replace: replaceCode,
              })}
            </Grid>
          </Box>
        )}

        {fields?.authors?.length > 0 && (
          <footer sx={{ mb: 10 }}>
            <Container variant="narrow">
              <Themed.hr />
              <Heading as="h2" variant="uppercase" mb={5} mt={9}>
                About the speaker
              </Heading>
              {fields?.authors?.map(author => (
                <Author key={author.id} author={author} />
              ))}
            </Container>
          </footer>
        )}
      </article>
      <ContributeCta
        socialShare={{
          title: fields.title,
          url: `https://datacentricai.org/blog/${post.slug}/`,
        }}
      />
    </Layout>
  )
}

export default BlogPostPresentationTemplate

export const pageQuery = graphql`
  query BlogPostPresentationById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      ...WpPostFields
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      slug
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      slug
      title
    }
  }
`
