/** @jsx jsx */
import { useEffect } from "react"
import { jsx, Box, Flex, Grid, Container, Heading, Text } from "theme-ui"
import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import parse from "html-react-parser"
import theme from "../../gatsby-plugin-theme-ui"

import AuthorsListCompact from "../AuthorsListCompact"

type PostHeaderProps = {
  fields: {
    title: string
    topic?: {
      title: string
      accentColor: string
      slug: string
    }
    excerpt?: string
    featuredImage?: {
      data: IGatsbyImageData
      alt: string
    }
    authors?: {
      image: {
        data: IGatsbyImageData
        alt: string
      }
      name: string
      shortBio: string
    }[]
    date: {
      formatted: string
      machineReadable: string
    }
    slidesliveId: string
  }
}

const HeaderSlidesLive: React.FC<PostHeaderProps> = ({ fields }) => {
  const { title, topic, excerpt, authors, featuredImage, date, slidesliveId } =
    fields

  useEffect(() => {
    var script = document.createElement("script")
    script.type = "text/javascript"
    script.onload = function () {
      let embed = new SlidesLiveEmbed(`presentation-embed-${slidesliveId}`, {
        presentationId: slidesliveId,
        autoPlay: false, // change to true to autoplay the embedded presentation
        verticalEnabled: true,
      })
    }
    script.src = "https://slideslive.com/embed_presentation.js"
    document.getElementsByTagName("head")[0].appendChild(script)
  }, [slidesliveId])

  return (
    <Box
      as="header"
      sx={{ backgroundColor: "background.dark2x", position: "relative" }}
    >
      <Container variant="narrow" pt={10}>
        <Flex sx={{ flexDirection: "column" }}>
          {topic && (
            <Heading
              as="div"
              variant="uppercase"
              sx={{ color: topic.accentColor }}
              mb={3}
            >
              <Link to={`/${topic.slug}/`}>{topic.title}</Link>
            </Heading>
          )}
          <Heading as="h1" variant="xl">
            {title}
          </Heading>
          <Box mt={5}>
            <AuthorsListCompact authors={authors} date={date} />
          </Box>
        </Flex>
      </Container>
      <Container
        sx={{
          position: "relative",
          zIndex: 1,
          mt: 4,
          minHeight: "399px",
        }}
      >
        <div
          id={`presentation-embed-${slidesliveId}`}
          sx={{
            ".slp__video": {
              borderRadius: theme.radii[5],
            },
          }}
        ></div>
      </Container>
      <div
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          left: 0,
          bg: "white",
          zIndex: 0,
          height: "100px",
        }}
      ></div>
    </Box>
  )
}

export default HeaderSlidesLive
